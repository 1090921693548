<template>
    <div id="app" :class="{ 'loading': !preloaded && $route.name === 'Landing' }">
        <router-view :key="$route.name"></router-view>

        <div class="cookie-bar" v-if="( hasConsent === 0 || hasConsent === '0' ) && $route.name !== 'PreLaunch'">
            <div class="row" v-if="!cookieSettings">
                <div class="col-12 m-col-9">
                    <h3>Sigma gebruikt cookies</h3>
                    <p>Sigma maakt gebruik van cookies om de basisfunctionaliteit van de website aan te bieden, om gebruikersstatistieken te genereren met behulp van Google Analytics op anonieme basis om onze website gebruiksvriendelijker te maken en om advertenties op maat te maken op de website van SIgma en andere PPG websites. Wij verwerken persoonsgegevens in overeenstemming met de AVG.</p>
                    <p>Wij vragen uw toestemming voor het gebruik van cookies. Zie onze <a href="https://corporate.ppg.com/getdoc/d0abacdf-a515-4f91-9881-9590c2b87a0d/PPG-PRIVACYVERKLARING-VOOR-EUROPA.aspx" target="_blank">privacyverklaring voor meer informatie</a></p>
                </div>
                <div class="col-12 m-col-3">
                    <button class="white" @click="giveConsent( '2' )">Accepteer cookies</button><br />
                    <a class="text" @click="cookieSettings = true" href="#">Uw cookie instellingen</a>
                </div>
            </div>
            <div class="row settings" v-else>
                <div class="col-12 m-col-9">
                    <h3>Cookie instellingen</h3>
                    <h4>Strikt noodzakelijke cookies (minimale cookies)</h4>
                    <p>Essentiële cookies om door de website te kunnen navigeren en de functies ervan te gebruiken, zoals toegang tot beveiligde delen van de website, het gebruik van winkelmandjes of e-billing.</p>
                    <h4>Cookies voor het verbeteren van Sigma</h4>
                    <p>Deze omvatten (i) prestatiecookies om informatie te verzamelen en te analyseren over hoe u onze website gebruikt; (ii) social-mediacookies zodat u de inhoud van onze website via sociale media kunt delen; (iii) targeting cookies om ons in staat te stellen advertenties aan te bieden die beter aansluiten op u en uw interesses.</p>
                </div>
                <div class="col-12 m-col-3">
                    <button class="white" @click="giveConsent( '2' )">Accepteer alle cookies</button><br />
                    <a @click="giveConsent( '3' )" href="#">Alleen minimale cookies</a>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';

    .cookie-bar{
        position:fixed;
        width:100%;
        bottom:0;
        left:0;

        background-color:rgba( 0,0,0,0.75 );
        color:white;

        text-align:left;
        z-index:20;

        h3{
            text-transform:uppercase;
            font-weight:500;
        }
        h4 {
            margin: 0;
            font-size: 1.1em;
        }
        p {
            margin-top: 0;
        }

        .row{
            max-width:1400px;
            margin:0 auto;

            &>div{
                padding:1rem;
            }
        }

        button{
            font-size:1rem;
            margin-bottom:0.75rem;
            width:180px;
            max-width:100%;

            padding:0.75rem 1rem;
        }

        a{
            color:white;
            font-weight:400;
            text-decoration:underline;
        }

        @media screen and ( min-width:$small ){
            button.white{
                margin-top:4rem;
            }
        }
    }
</style>

<script>
    export default {
        name: 'App',

        data()
        {
            return {
                cookieSettings: false
            };
        },

        computed: {
            preloaded()
            {
                return this.$store.getters[ 'preloader/done' ];
            },

            hasConsent()
            {
                return this.$store.getters[ 'hasConsent' ];
            }
        },

        watch: {
            hasConsent: function( pVal )
            {
                // console.log( 'hasConsent', pVal );
            }
        },

        mounted()
        {
            // console.log( 'hasConsent', this.hasConsent );
            window.addEventListener( 'resize', this.onResize );
            this.onResize();
        },

        methods: {
            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones
                document.documentElement.style.setProperty( '--vw100', document.body.clientWidth + 'px' ); // fix for 100vh on iphones

                this.$store.dispatch( 'newSize', document.body.clientWidth );
            },

            giveConsent( pWhich )
            {
                this.$store.dispatch( 'setConsent', pWhich );
            }
        }
    };
</script>

import Vue from 'vue';
import Router from 'vue-router';
import store from '@/app/store';

import PreLaunch from '@/app/pages/PreLaunch';

let liveDate = new Date( 2021, 2, 1, 16, 30, 0 );
let now = new Date();

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/pre-launch',
            name: 'PreLaunch',
            component: PreLaunch
        },
        {
            path: '/',
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            }
        },
        {
            path: '/articles/',
            name: 'Articles',
            component: () =>
            {
                return import( /* webpackChunkName: "articles" */ '@/app/pages/Articles' );
            },

            children: [
                {
                    path: 'kleuren',
                    component: () =>
                    {
                        return import( /* webpackChunkName: "article-one" */ '@/app/articles/One' );
                    }
                },
                {
                    path: 'innovatie',
                    component: () =>
                    {
                        return import( /* webpackChunkName: "article-two" */ '@/app/articles/Two' );
                    }
                },
                {
                    path: 'sneller',
                    component: () =>
                    {
                        return import( /* webpackChunkName: "article-three" */ '@/app/articles/Three' );
                    }
                },
                {
                    path: 'schilder-van-de-toekomst',
                    component: () =>
                    {
                        return import( /* webpackChunkName: "article-four" */ '@/app/articles/Four' );
                    }
                },
                {
                    path: 'schilders-spuiten-stadion',
                    component: () =>
                    {
                        return import( /* webpackChunkName: "article-five" */ '@/app/articles/Five' );
                    }
                },
                {
                    path: 'ppg-lanceert-spraymaster',
                    component: () =>
                    {
                        return import( /* webpackChunkName: "article-six" */ '@/app/articles/Six' );
                    }
                },
                {
                    path: 'voordeel-op-veel-vlakken',
                    component: () =>
                    {
                        return import( /* webpackChunkName: "article-seven" */ '@/app/articles/Seven' );
                    }
                },
                {
                    path: 'nu-ook-voor-binnen',
                    component: () =>
                    {
                        return import( /* webpackChunkName: "article-eight" */ '@/app/articles/nu-ook-voor-binnen.vue' );
                    }
                },
                {
                    path: 'project-school-in-bilthoven',
                    component: () =>
                    {
                        return import( /* webpackChunkName: "article-nine" */ '@/app/articles/project-school-in-bilthoven' );
                    }
                }
            ]
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( now < liveDate && to.name !== 'PreLaunch' )
    {
        next( '/pre-launch' );
        return;
    }

    if( now > liveDate && to.name === 'PreLaunch' )
    {
        next( '/' );
        return;
    }

    if( to.hash.length )
    {
        store.dispatch( 'setDeeplink', to.hash );
    }

    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;

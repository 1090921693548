export default {
    namespaced: true,

    state: {
        queue: null,
        preloaded: false,

        imgSequences: [
            // { id: 'walkcycle', start: 1, end: 116, name: 'walkcycle.', type: '.png', zeroFill: true },
            { id: 'hatch-open', start: 1, end: 150, name: 'Hatch-Open.', type: '.png' }
            // { id: 'spraymaster-rotation-jpeg', start: 200, end: 301, name: 'SprayMaster_S2U_Allure.', type: '.jpg' },
            // { id: 'op-backpack', start: 1, end: 181, name: 'BackPack.', type: '.png' }
        ]
    },

    getters: {
        done: state => state.preloaded,
        // queue: state => window.queue,

        getBlobs: state => ( pId ) =>
        {
            if( state.preloaded )
            {
                let seq = state.imgSequences.find( item => item.id === pId );

                let blobList = [];

                for( let i = seq.start; i <= seq.end; i++ )
                {
                    let blob;
                    // console.log( seq.zeroFill );
                    if( seq.zeroFill )
                    {
                        // console.log( 'getResult', seq.id + '-' + i.toString().padStart( 4, '0' ) );
                        blob = window.queue.getResult( seq.id + '-' + i, true );
                    }
                    else
                    {
                        blob = window.queue.getResult( seq.id + '-' + i, true );
                    }

                    blobList.push( URL.createObjectURL( blob ) );
                }

                return blobList;
            }
            else
            {
                // console.log( 'returning empty array' );
                return [];
            }
        }
    },

    mutations: {
        makeQueue: ( state, payload ) =>
        {
            window.queue.loadManifest( payload );
        },

        preloadDone: state =>
        {
            state.preloaded = true;
        }
    },

    actions: {
        startPreload: ( context, payload ) =>
        {
            if( context.getters.done )
            {
                return;
            }

            window.queue = new createjs.LoadQueue();
            window.queue.on( 'complete', () =>
            {
                // console.log( 'COMPLETED, PRELOAD = TRUE' );
                // state.preloaded = true;
                context.dispatch( 'preloadDone' );
            });

            let manifest = [];

            for( let seq of context.state.imgSequences )
            {
                for( let i = seq.start; i <= seq.end; i++ )
                {
                    if( seq.zeroFill )
                    {
                        // console.log( 'push', '/static/img/imgseq/' + seq.id + '/' + seq.name + i.toString().padStart( 4, '0' ) + seq.type );
                        manifest.push({ id: seq.id + '-' + i, src: '/static/img/imgseq/' + seq.id + '/' + seq.name + i.toString().padStart( 4, '0' ) + seq.type });
                    }
                    else
                    {
                        manifest.push({ id: seq.id + '-' + i, src: '/static/img/imgseq/' + seq.id + '/' + seq.name + i + seq.type });
                    }
                }
            }
            // console.log( 'making queue', manifest );
            context.commit( 'makeQueue', manifest );
        },

        preloadDone: context =>
        {
            context.commit( 'preloadDone' );
        }
    }
};

<template>
    <div class="pre-launch-page">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="638.8px" height="119.3px" viewBox="0 0 638.8 119.3" style="enable-background:new 0 0 638.8 119.3;" xml:space="preserve">
            <defs>
            </defs>
            <g>
                <g>
                    <g>
                        <path class="st0" d="M122.4,119.3H28.6C12.8,119.3,0,106.5,0,90.8V28.6C0,12.8,12.8,0,28.6,0h93.8C138.2,0,151,12.8,151,28.6
                        v62.2C151,106.5,138.2,119.3,122.4,119.3z"/>
                        <path class="st1" d="M122.4,114.3H28.6c-13,0-23.6-10.6-23.6-23.6V28.6C5,15.6,15.6,5,28.6,5h93.8c13,0,23.6,10.6,23.6,23.6v62.2
                        C146,103.7,135.4,114.3,122.4,114.3z"/>
                        <path class="st0" d="M123.4,40.6c5.6-1.7,11.4,0.1,15.9,3.8V32.4c-14.7-2.3-22.4-0.7-28.3,2.9c-4.8,3-6.8,7.2-6.8,7.2
                        s-0.1,0.2-0.3,0.6c-0.8-30.6-19.5-28-41.9-28c-6.3,0-12.6,0-12.6,0v26.4c-3.7-10.9-15.2-9.8-27.5-9.8c-4.5,0-10.3,0-10.3,0v55.9
                        h16.9c-2.8-1-4.3-3.4-4.3-7V65.3l4.9,4.9c2.2,0.5,4,0.5,4,0.5c4.7-0.3,13.1-2.1,16.2-11.5v45.2h20.1c-2.8-1-4.2-3.4-4.2-6.9V67
                        l7.6,7.6c2.5,0.9,4.3,1,6.4,0.9c5.9-0.4,15.9-2.8,21.2-13.5c0.4,10,3.7,20,13.8,23.9c4.5,1.8,9.5,2.3,14.8,1.9
                        c3.4-0.1,6.8-0.4,10.1-1V59.6l-15.4,0c2.8,1,4.3,3.3,4.3,6.9v12.5c-3.5,1.1-8.3,0-10.9-3.4c-3.3-4.3-4-16.5-2.5-24.8
                        C115.5,46.5,119.1,41.9,123.4,40.6z M30.1,63.1c-4.1,0-5.8-1.8-5.8-1.8V41.3c0-1.7,1.2-2.9,3.1-3.1c2-0.2,11.1-1.7,11.1,11.8
                        C38.5,62.5,33,63.1,30.1,63.1z M75.6,65.2c-6.2,0-10.2-2.9-10.2-2.9V29c0-2.8,3.3-4.7,6.1-5c3-0.4,16.9-2.5,16.9,19.4
                        C88.4,63,79,65.2,75.6,65.2z"/>
                    </g>
                </g>
            </g>
            <g>
                <path class="st0" d="M206.1,29.5c11,0,18.9,5.5,21,15.1h-5.4c-1.9-6.5-7.5-10.1-16.1-10.1c-5.7,0-13.7,2.3-13.7,9.6
                c0,5.7,4.6,8.5,10.9,9.9l6.7,1.5c8.1,1.9,18.9,5.1,18.9,15.8c0,10.8-9.9,16.5-20.8,16.5c-14,0-21.8-7.8-23-17.9h5.4
                c1.9,8.9,8.2,13,17.4,13c6.1,0,15.6-2.3,15.6-10.6c0-6.9-6.7-10.1-14.6-11.8l-7.1-1.6c-7.4-1.7-14.7-5.3-14.7-14.5
                C186.5,34,197,29.5,206.1,29.5z"/>
                <path class="st0" d="M254.9,87.8c-6.1,0-11.2-2.9-14.1-7.9v20.5h-4.8V45.1h4.8v7.1c2.9-5,8-7.8,14.1-7.8c9.6,0,17.5,7.8,17.5,21.7
                C272.3,79.9,264.5,87.8,254.9,87.8z M253.9,83.5c7.8,0,13.3-6.3,13.3-17.4c0-11.1-5.5-17.4-13.3-17.4c-6.3,0-13.4,5-13.4,17.4
                C240.4,78.4,247.6,83.5,253.9,83.5z"/>
                <path class="st0" d="M298.9,49.9c-0.8-0.1-1.5-0.1-2.2-0.1c-8.6,0-11.4,6.2-11.4,15.1v21.9h-4.7V45.1h4.6v7.6
                c1.9-4.9,7.3-7.9,12.3-7.9c0.4,0,0.9,0,1.4,0.1V49.9z"/>
                <path class="st0" d="M330.7,80.4c-2.7,4.4-7.9,7.4-15.7,7.4s-13.8-4.5-13.8-11.4c0-9.5,9.3-13,29.4-15V60c0-9.5-5.6-11.6-11.6-11.6
                c-7.1,0-11.3,4-11.7,9.4h-5c1.1-8.2,7.8-13.7,17.1-13.7c9.7,0,16.1,4,16.1,16.5c0,5.7-0.1,9.5-0.1,12.9c0,4.7,0.2,8.8,0.5,13.3
                h-4.9L330.7,80.4z M316.1,83.6c7.8,0,14.7-4,14.7-15.8v-2c-17,1.9-24.3,4.1-24.3,10.6C306.5,80.8,310.6,83.6,316.1,83.6z"/>
                <path class="st0" d="M343.2,100.4v-4.2h2.8c4,0,5.9-0.2,8.1-6.1l1.3-3.3l-16-41.8h5.4l13,35.1l12.6-35.1h5.2L358.5,91
                c-2.9,8.1-5,9.4-12.5,9.4H343.2z"/>
                <path class="st0" d="M406.6,86.8l-19.1-51.3v51.3h-5.2V30.5h8.4l18.6,50.4l18.5-50.4h8.3v56.3h-5.2V35.5l-19.1,51.3H406.6z"/>
                <path class="st0" d="M474.9,80.4c-2.7,4.4-7.9,7.4-15.7,7.4c-7.8,0-13.8-4.5-13.8-11.4c0-9.5,9.3-13,29.4-15V60
                c0-9.5-5.6-11.6-11.6-11.6c-7.1,0-11.3,4-11.7,9.4h-5c1.1-8.2,7.8-13.7,17.1-13.7c9.7,0,16.1,4,16.1,16.5c0,5.7-0.1,9.5-0.1,12.9
                c0,4.7,0.2,8.8,0.5,13.3h-4.9L474.9,80.4z M460.3,83.6c7.8,0,14.7-4,14.7-15.8v-2c-17,1.9-24.3,4.1-24.3,10.6
                C450.7,80.8,454.7,83.6,460.3,83.6z"/>
                <path class="st0" d="M514.7,56.5c-1-5-5.6-7.9-11.5-7.9c-4.6,0-10.6,1.8-10.6,6.8c0,4.1,3.3,6.3,7.8,7.1l5.9,1.1
                c6.8,1.2,14.4,3.6,14.4,11.5c0,8.3-7.5,12.7-16.6,12.7c-8.6,0-16.1-4.3-17.3-13.8h5c0.9,6.6,6.2,9.5,12.6,9.5
                c5,0,11.3-2.1,11.3-7.7c0-4.9-4.7-6.6-10.3-7.7l-6.5-1.2c-6.1-1.2-11.3-4.7-11.3-11.2c0-6.6,6.6-11.5,16-11.5
                c8.9,0,15.1,5.5,16.1,12.2H514.7z"/>
                <path class="st0" d="M532,35.7h4.9v9.4h8.2v4.2h-8.2v26.7c0,5.4,0.2,6.5,5.7,6.5h2.5v4.3h-4c-7.4,0-9.2-1.7-9.2-9.9V49.2h-6.9v-4.2
                h6.9V35.7z"/>
                <path class="st0" d="M550.2,66.1c0-13.5,8.4-21.7,19.6-21.7c11.8,0,19.2,9.9,18.6,23h-33.2c0.2,10.1,6.5,16.1,15.1,16.1
                c6.5,0,11.1-3.3,12.7-9.1h5c-2,8.6-8.9,13.5-18.1,13.5C557.6,87.8,550.2,79,550.2,66.1z M555.3,62.9h27.8c0-7.1-5-14.2-13.4-14.2
                C561.9,48.7,556.3,53.7,555.3,62.9z"/>
                <path class="st0" d="M615.1,49.9c-0.8-0.1-1.5-0.1-2.2-0.1c-8.6,0-11.4,6.2-11.4,15.1v21.9h-4.7V45.1h4.6v7.6
                c1.9-4.9,7.3-7.9,12.3-7.9c0.4,0,0.9,0,1.4,0.1V49.9z"/>
            </g>
            <g>
                <path class="st0" d="M628.4,30.1c5.7,0,10.4,4.2,10.4,10.4c0,6.2-4.7,10.4-10.4,10.4c-5.8,0-10.4-4.2-10.4-10.4
                C618,34.4,622.7,30.1,628.4,30.1z M628.4,49.4c5,0,8.7-3.6,8.7-8.9c0-5.3-3.7-8.9-8.7-8.9c-4.9,0-8.7,3.6-8.7,8.9
                C619.7,45.8,623.5,49.4,628.4,49.4z M629.3,41.4c-0.2,0-0.4,0-0.6,0h-2.5v4.3h-1.6v-11h3.3c3.2,0,5.2,0.5,5.2,3.4
                c0,1.2-0.7,2.4-2.1,3l2.5,4.7h-1.9L629.3,41.4z M628.5,36.3h-2.4v3.5h2.5c1.8,0,2.7-0.6,2.7-1.8C631.3,36.7,630.1,36.3,628.5,36.3z
                "/>
            </g>
        </svg>
    </div>
</template>

<style lang="scss" scoped>
    .st0{fill:#FFFFFF;}
    .st1{fill:#0073A5;}
</style>

<script>
    export default {

    };
</script>

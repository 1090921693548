import Vue from 'vue';
import Vuex from 'vuex';
import Cookie from 'js-cookie';

import entry from './modules/entry';
import preloader from './modules/preloader';

if( !Cookie.get( 'cookieConsent' ) )
{
    Cookie.set( 'cookieConsent', 0, { path: '/', domain: window._rootData.cDomain, expires: 365, httpOnly: false });
}

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        entry,
        preloader
    },

    state: {
        currentVideo: 'unboxing',
        deeplink: null,
        cookieConsent: Cookie.get( 'cookieConsent' ) ? Cookie.get( 'cookieConsent' ) : 0,
        browserSize: null
    },

    getters: {
        currentVideo: state => state.currentVideo,
        deeplink: state => state.deeplink,
        hasConsent: state => state.cookieConsent,
        isMobileWidth: state => state.browserSize < 720
    },

    mutations: {
        setCurrentVideo: ( state, payload ) =>
        {
            state.currentVideo = payload;
        },

        setDeeplink: ( state, payload ) =>
        {
            state.deeplink = payload;
        },

        setConsent: ( state, payload ) =>
        {
            state.cookieConsent = payload;
            Cookie.remove( 'cookieConsent' );
            Cookie.set( 'cookieConsent', payload, { path: '/', domain: window._rootData.cDomain, expires: 365, httpOnly: false });
            // console.log( 'cookie has been set to:', payload );
        },

        newSize: ( state, payload ) =>
        {
            state.browserSize = payload;
        }
    },

    actions: {
        setCurrentVideo: ( context, payload ) =>
        {
            context.commit( 'setCurrentVideo', payload );
        },

        setDeeplink: ( context, payload ) =>
        {
            context.commit( 'setDeeplink', payload );
        },

        setConsent: ( context, payload ) =>
        {
            context.commit( 'setConsent', payload );
        },

        newSize: ( context, payload ) =>
        {
            context.commit( 'newSize', payload );
        }
    }
});
